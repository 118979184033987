body {
	background: #06101d;
}


h1 {
	font-size: 68px;
}


h2 {
	font-size: 48px;
}

.navbar {
	background-color: #06101d;
	padding: 10px;
}

.navbar .container-fluid {
	padding: 0px 10px;
}


.header-line {
	height: 80px;
	background-color: #06101d;
}

.loginLink {
	padding-left: 45px !important;
	padding-right: 45px !important;
}

.logo-link.linkActive {
	box-shadow: none;
	background: none;
}

.main-wrap {
	// padding: 112px 0;
	padding: 58px 0;
	background-color: #09101E;
	background-image: url('../img/back1.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position-x: center;
}

.btn-primary {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	background: linear-gradient(84.35deg, #FF0E46 11.46%, #E30036 31.95%, #FD264E 55.35%, #FF345D 76.53%, #FD0A46 97.17%);
	border: 2px solid #FF5471;
	box-shadow: 0px 0px 16px #F0043E;
	border-radius: 100px;
	padding: 18px 70px;
	font-size: 20px;
	text-transform: uppercase;
	color: #FAFAFA;
	transition: .5s;
}


.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus {
	border: 2px solid #FF5471;
	box-shadow: 0px 0px 16px #F0043E;
}

.btn-primary:hover {
	border-color: #c62a3f;
}

.nav-link {
	transition: .5s;
}

.main-description {
	max-width: 560px;
	text-align: center;
}

.main-description--end {
	max-width: 7000px;
	text-align: center;
}

.btn-secondary {
	padding: 18px 70px;
	background: linear-gradient(84.35deg, #44A9FF 11.46%, #0080FF 31.95%, #0098F9 55.35%, #009BF8 76.53%, #009BF8 97.17%);
	border: 2px solid #79C6FF;
	box-shadow: 0px 0px 16px 0px #5EB4FE;
	border-radius: 100px;
	font-size: 20px;
	font-weight: 700;
	text-transform: uppercase;
	color: #FAFAFA;
	transition: .5s;
}

.btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus {
	border: 2px solid #79C6FF;
	box-shadow: 0px 0px 16px 0px #5EB4FE;
}

.footer {
	display: flex;
	padding: 20px;
	justify-content: center;
	align-items: center;
	background-color: #06101d;

	&__link {
		color: #ffffff;
		text-align: left;
		margin-bottom: 0px;
		margin-left: 5px;
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: min(max(10px, 8vw), 14px);
		text-decoration: none;

		&:hover {
			color: #ffffff;
			opacity: .9;
		}
	}
}


.about-wrap {
	padding: 48px 0;
	background-color: #09101E;
	background-image: url('../img/back2.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position-x: center;
}

.rowElements {
	flex-wrap: wrap;
}


.parag {
	width: 200px;
	display: flex;
	align-items: center;
}


.parag--first,
.parag--center {
	width: 100px;
}


.rowElements.feature-wrap {
	margin-top: 45px;
}


.feature-block {
	font-size: 16px;
	display: flex;
	align-items: center;
	max-width: 170px;
}

.feature-block:nth-child(1) {
	max-width: 110px;
}


.feature-title {
	margin-left: 12px;
	color: #ffffff;
	font-size: 16px;
	text-align: left;
}


.footer-item {
	display: flex;
	align-items: center;
}

@media (max-width: 1360px) {
	.footer {
		justify-content: center;
	}

	.footer__container-end {
		position: inherit;
	}
}

@media (max-width: 1000px) {
	.footer {
		display: block;
		width: 200px;
		margin: 0 auto;
		padding: 20px 0;
	}

	.footer-item:not(:last-child) {
		margin: 0 0 12px 0 !important;
	}

	.footer-item:last-child {
		margin: 0 0 0 0 !important;
	}

	.footer__container-end {
		display: block;
		position: inherit;

		.footer__link {
			margin-left: 20px;
		}
	}

}

@media (max-width: 768px) {
	h1 {
		font-size: 58px;
	}

	p {
		font-size: 14px;
	}

	.main-description {
		font-size: 24px !important;
	}

	.main-description--end {
		max-width: 750px;
	}
}


@media (max-width: 620px) {

	h1 {
		font-size: 48px;
	}

	h2 {
		font-size: 36px;
	}


	.main-wrap {
		padding: 80px 0;
		background-image: url('../img/mob-bg.png');


	}

	.about-wrap {
		background-image: url('../img/mob-bg-footer.png');
	}

}

@media (max-width: 590px) {

	.btn-secondary {
		margin-top: 10px !important;
	}

	.rowElements.feature-wrap {
		margin-top: 20px;
		margin-bottom: 5px !important;
		max-width: 230px;
	}

	.feature-block,
	.feature-block:nth-child(1) {
		font-size: 14px;
		max-width: 220px;
	}


	.feature-block:nth-child(2n) {
		max-width: 180px;
	}

	.rowElements {
		max-width: 200px;
		margin: 0 auto;
		text-align: center;
		flex-direction: column;
		align-items: flex-start;
	}

	.rowElements>div {
		margin-bottom: 20px;
	}


	.parag {
		width: 100%;
		display: flex;
		align-items: center;
	}
}

@media (max-width: 500px) {

	.btn-primary {
		padding: 18px 40px;
	}

	.btn-secondary {
		padding: 18px 40px;
	}

	.logo-link {
		padding: 0 !important;
	}

	.main-description {
		padding-left: 10px;
		padding-right: 10px;
		max-width: 450px;
		font-size: 18px !important;
	}

	.main-description--end {
		max-width: 490px;
	}
}


@media (max-width: 990px) {
	.loginLink {
		padding-left: 10px !important;
		padding-right: 10px !important;
	}

}