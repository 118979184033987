// @import "~bootstrap/scss/bootstrap";
@import url(./bootstrap.min.css);
@import url(./header-item.scss);
@import url(./Header---Apple.scss);
@import url(./footer.scss);
@import url(./styles.scss);
@import url(./coockie.scss);

$primary-color: #f7344e;
$secondary-color: #3099f3;
$link-color: #309af4;

body {
  background: #09101e;
  overflow-x: hidden;
}

.btn-close {
  position: absolute;
  top: 30px;
  right: 30px;
  opacity: 0.3;
}

.modal-lg {
  max-width: 390px;
}

.modal-header {
  padding: 0;
  display: block;
  border: none;
}

.modal-body {
  padding: 0;
}

.modal-content {
  padding: 24px 20px;
  font-family: 'Inter';
  border-radius: 20px;
}

.sign-form {
  &__title {
    margin-bottom: 8px;
    color: $primary-color;
    font-size: 24px;
    font-weight: 700;

    &--primary {
      color: $primary-color;
    }

    &--secondary {
      color: $secondary-color;
    }
  }

  &__subtitle {
    font-weight: 400;
    font-size: 14px;
    color: #1d2635;
  }

  .hint {
    color: #7a8495;
    font-size: 14px;
  }

  &__link {
    font-size: 14px;
    color: $link-color;
    text-decoration: none;
    font-weight: 500;
    display: inline;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &--underline {
      padding-bottom: 2px;
      border-bottom: 1px solid $link-color;
      font-weight: 400;
    }
  }

  .btn {
    padding: 18px;
    border-radius: 6px;
    border: none;
    box-shadow: none;
    font-weight: 500;
    font-size: 16px;
    font-weight: 500;
    font-size: 16px;
    text-transform: inherit;
  }

  .btn-primary {
    background: linear-gradient(90deg, #f7344e 0%, #cb243a 100%);
  }

  .btn-secondary {
    background: linear-gradient(260.44deg, #006fcc 11.79%, #3099f3 89%);
  }
}

.caret {
  &::after {
    content: url(../img/caret.svg);
  }
}

.input-icon {
  width: 35px;
  padding: 0px 16px 0px 0px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0px;
  z-index: 10;
  margin: auto;
  cursor: pointer;
}

input:-internal-autofill-selected,
input:-webkit-autofill::first-line,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // border: none;
  // border-radius: 20px;
  // font-family: Aeroport,
  // sans-serif;
  transition: background-color 600000s ease 0s,
    color 600000s ease 0s;
  z-index: 0;
  box-shadow: #f3f4f5 0px 0px 100px 100px inset;
  // font-size: 18px !important;
  // -webkit-text-fill-color: white !important;
}

.form-control {
  padding: 18px;
  background: #f3f4f5;
  border: none;
  border-radius: 10px;

  &::placeholder {
    color: #7a8495;
  }

  &:active,
  &:focus,
  &:hover {
    background-color: #f3f4f5;
    box-shadow: none;
    outline: none;
  }

  &__error {
    color: #fa3535;
    font-size: 12px;
  }
}

/* для элемента input c type="checkbox" */
.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

/* для элемента label, связанного с .custom-checkbox */
.custom-checkbox+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

/* создание в label псевдоэлемента before со следующими стилями */
.custom-checkbox+label::before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 0.25em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

/* стили при наведении курсора на checkbox */
.custom-checkbox:not(:disabled):not(:checked)+label:hover::before {
  border-color: #b3d7ff;
}

/* стили для активного чекбокса (при нажатии на него) */
.custom-checkbox:not(:disabled):active+label::before {
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

/* стили для чекбокса, находящегося в фокусе */
.custom-checkbox:focus+label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
.custom-checkbox:focus:not(:checked)+label::before {
  border-color: #80bdff;
}

/* стили для чекбокса, находящегося в состоянии checked */
.custom-checkbox:checked+label::before {
  border-color: #0b76ef;
  background-color: #0b76ef;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

/* стили для чекбокса, находящегося в состоянии disabled */
.custom-checkbox:disabled+label::before {
  background-color: #e9ecef;
}

.document-page {
  color: #fff;
  max-width: 1247px;
  margin: 20px auto 100px auto;
  padding: 0 20px;

  p {
    font-size: 16px;
  }

  &__version {
    text-align: center;
    margin-bottom: 20px;
  }

  &__inner {
    padding: 24px 24px 10px;
    background: #1d2635;
    border-radius: 12px;
  }
  
  &__title {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__sub-title {
    margin-top: 32px;
    font-size: 28px;
    font-weight: 500;
  }

  &__link {
    display: inline;
    color: $link-color;
    font-size: 16px;
  }

  ul {
    position: relative;
    list-style: none;
    margin-left: 0;
    padding-left: 1.2em;
  }

  ul li:before {
    content: "\2022";
    color: $link-color;
    font-weight: bold;
    display: inline-block;
    width: 16px;
    margin-left: -1em;
  }
}

.page-404 {
  color: #fff;
  margin: 50px auto;
  text-align: center;
}

.spinner-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}