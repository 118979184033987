a {
  /*width: 141px;*/
  /*height: 26px;*/
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 146.28%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #FAFAFA;
  flex: none;
  order: 0;
  flex-grow: 0;
}

