#contacts {
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.link:hover {
  background: linear-gradient(180deg, rgba(26, 33, 44, 0) 74%, #03579E 100%);
  box-shadow: 0 3px #2790E9;
}

.link:active {
  background: linear-gradient(180deg, rgba(26, 33, 44, 0) 74%, #03579E 100%);
  box-shadow: 0 3px #2790E9;
}

.link:focus {
  background: linear-gradient(180deg, rgba(26, 33, 44, 0) 74%, #03579E 100%);
  box-shadow: 0 3px #2790E9;
}

.linkActive {
  box-shadow: 0 3px #2790E9;
  background: linear-gradient(180deg, rgba(26, 33, 44, 0) 74%, #03579E 100%);
}

#login:hover {
  background: rgba(247, 52, 78, 1);
}

#login:hover .loginLink {
  color: rgba(29, 38, 53, 1);
}

#login .loginLink {
  color: #FAFAFA;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 146.3%;
  align-items: center;
  text-align: center;
  letter-spacing: 0.015em;
  width: 100%;
  cursor: pointer;
}

#signUp:hover {
  background: linear-gradient(260.44deg, #004b8b 11.79%, #2370b1 89%);
}

#signUp {
  background: linear-gradient(260.44deg, #005ca9 11.79%, #2981cc 89%);
  width: 11.5rem;
  max-width: 15rem;
  height: 40px;
  min-width: 4rem;
  background: linear-gradient(260.44deg, #006FCC 11.79%, #3099F3 89%);
  border-radius: 6px;
  flex: none;
  order: 2;
  flex-grow: 0;
  /* margin-right: 40px; */
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#start:hover {
  background: rgb(198,42,63);
}

#start {
  background: rgba(247, 52, 78, 1);
}

#bottomButton {
  background: linear-gradient(260.44deg, #006FCC 11.79%, #3099F3 89%);
}

#bottomButton:hover {
  background: linear-gradient(260.44deg, #005ca9 11.79%, #2981cc 89%);
}

@media (max-width: 990px) {
  #login {
    width: 1rem;
    max-width: 1rem;
    min-width: 100px;
  }
}

@media (max-width: 990px) {
  #signUp {
    width: 8rem;
    max-width: 7rem;
    min-width: 4rem;
    /* margin-right: 10px; */
  }
}

@media (max-width: 600px) {
  #bottomInfo {
    padding-right: 0px;
    padding-left: 0px;
  }
}

.padBottom {
}

@media (min-width: 1150px) {
  .padBottom {
    padding-left: 40px;
  }
}

@media (max-width: 1150px) {
  .padBottom {
    padding-bottom: 40px;
  }
}

#login {
  color: var(--bs-light);
  border-width: medium;
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 2px solid rgba(247, 52, 78, 1);
  margin-right: 10px;
}

.loginLink {
  width: 140px;
}

#topBanner {
  text-align: center;
  margin: 0px;
  margin-bottom: 0px;
}

@media (max-width: 600px) {
  #topBanner {
    padding-top: 50px;
  }
}

#headerItem {
  padding-left: 25px;
}

@media (max-width: 900px) {
  #headerItem {
    padding-left: 0px;
  }
}

@media (max-width: 767px) {
  #header-1 {
    display: none;
  }
}

.rowElements {
  max-width: 700px;
  margin: 0px auto;
  display: flex;
  text-align: center;
  justify-content: space-evenly;
    
}

@media (max-width: 500px) {
  .logo {
    padding-left: 5px;
  }
}

@media (max-width: 380px) {
  .logo {
    width: 4rem;
  }
}

#btb1 {
  display: flex;
}

#btb2 {
  display: none;
}

@media (max-width: 605px) {
  #btb2 {
    display: flex;
  }
}

@media (max-width: 605px) {
  #btb1 {
    display: none;
  }
}

#btb2 {
  height: 50px;
  margin-top: 50px;
  margin-bottom: 35px;
}

.about-us-parag {
  font-size: calc(12px + 1.2vw);
}

@media (min-width: 1450px) {
  .about-us-parag {
    font-size: 28px;
  }
}

.footer__container-end {
  display: flex;
  position: absolute;
  right: 0;
}