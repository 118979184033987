.notification-cookies-policy {
  padding: 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #222226;
  color: #ffffff;
  box-shadow: 0px 0px 16px 0px #0a1222;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-size: 20px;
  }

  &__link {
    display: inline;
    color: #309af4;
    text-decoration: none;
  }

  &__button {
    padding: 8px 12px;
    width: 80px;
    border-radius: 4px;
    text-align: center;
    background: #3099f3 linear-gradient(260.44deg, #006fcc 11.79%, #3099f3 89%);
    outline: none;
    border: none;
    color: #ffffff;
  }
}
